<template>
  <el-container style="height:850px;width: 365px">
    <el-main style="padding: 0px">
        <div v-loading="loading" style="margin:0 auto;margin-top: 20px;height: 100%;">
          <el-input style="width: 358px;" @keyup.enter.native="searchEnterFun" placeholder="搜一搜 总有您想要玩的~" v-model="gameName">
            <i @click="searchEnterFun" slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
          <ul id="gameListUl" class="infinite-list" v-infinite-scroll="load" style="overflow:auto;height: 100%;overflow: auto">
            <li @click="goDetail(data.id)" v-for="data in datas" class="infinite-list-item">
              <div style="padding: 10px; width: 150px;height: 80px">
                <div style="overflow: hidden">
                  <img :src="data.bgUrl" width="100%" height="100%" >
                </div>
              </div>
              <div style="font-size: 14px;text-align: left;height: 20px;padding: 0 10px 0 10px;">
                <a @click="goDetail(data.id)" :title="data.name">{{data.name|ellipsis}}</a>
              </div>
              <div style="font-size: 12px;padding: 5px;float: left;margin-top: 20px">
                <p style="margin-left: 5px"><i class="dot"></i>{{data.gameType==""?"其他游戏":data.gameType}}</p>
                <p style="margin-left: 5px;margin-top: 5px"><i class="el-icon-time"></i>{{data.updateTime}}</p>
              </div>
            </li>
          </ul>
        </div>
    </el-main>
  </el-container>
</template>
<script>

import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  data() {
    return {
      limit: 10,
      gameName: "",
      datas:{},
      needGame:"",
      dialogFormVisible:false,
      loading:false
    };
  },
  filters: {
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    },
  },
  // 卸载
  beforeDestroy() {
  },
  created() {
    this.showGame(this.limit)
    this.initRollPicture()
  },
  mounted() {
  },
  methods: {
    goHome(){
      window.location.href='/'
    },
    load () {
      this.limit += 10
      this.showGame(this.limit)
    },
    showGame(limit){
      this.loading = true;
      this.$axios.get(this.$gameApiurls.gameList+"?page=1&limit="+limit+"&name="+this.gameName)
        .then((res) => {
           var datas = res.data.list
           this.datas = datas;
           this.loading = false;
        });
    },goDetail(id){
      window.location.href='/mobileDetail?gameId='+id
    },searchEnterFun(){
      this.showGame(this.limit)
    }
  },
};
</script>

<style scoped>
body {
  height: 100vh;
  background-color: #f4fafe;
}

#headerUl>li{
  float: left;
  list-style: none;
}

a:hover{
  color:#1a284a;
  font-size: 15px;
}
.dot {
  border-radius: 50%;
  height: 6px;
  margin-right: 5px;
  width: 6px;
  background-color: #fd721f;
  display: inline-block;
}
#gameListUl>li{
  float: left;
  list-style: none;
  height: 185px;
  width: 170px;
  border:1px solid #ccc;
  margin-top: 10px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  box-shadow: 1px 4px 10px grey;
  background-color: white;
  opacity: 0.9;
}
.input-class .el-input__inner {
  text-align: center;
  border-radius: 30px;
  width: 380px;
  float: right;
  margin-right: 30%;
  margin-top:20px;
}

.glass-effect {
  height: 500px;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  opacity: 0.5;
}
/*.el-main{*/
/*  background: url("http://154.197.99.71:8082/file/bg02.jpg");*/
/*  !*background-size: contain;*!*/
/*}*/
</style>
